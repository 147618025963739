@import "../../styles/rwd";

.banner-comp {
  background-color: #d7d4cf;
  padding: 0;

  &-container {
    min-height: calc(100vh - 128px);
    position: relative;
    @include mobile {
      min-height: calc(100vh - 54px);
    }
    @include tablet {
      min-height: calc(100vh - 54px);
    }

    .left-img,
    .right-img {
      position: absolute;
      @include mobile {
        display: none;
      }
    }

    .left-img {
      background-image: url("../../assets/imgs/subBgBanner2.svg");
      background-position: center 20px;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
      width: 300px;
      height: 400px;
      bottom: 0;
      left: 0;
    }

    .right-img {
      background-image: url("../../assets/imgs/subBgBanner1.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
      width: 300px;
      height: 400px;
      bottom: 0;
      right: 0;
    }
  }
}

.banner-comp-container .banner-comp-content {
  height: 100%;
  width: 100%;
  .banner-connected {
    background: url("../../assets/imgs/bgBanner.png") no-repeat center;
    background-size: 100% 100%;
    width: 60%;
    min-height: 400px;
    padding: 20px;
    &-container {
      width: 60%;
    }
    @include mobile {
      width: 100%;
      &-container {
        width: 100%;
      }
    }
  }

  img {
    @include tablet {
      width: 70%;
    }
    @include desktop {
      width: 80%;
    }
    @include wide-desktop {
      width: 60%;
    }
  }
}
