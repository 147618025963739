@import "../../styles/rwd";

.roadmap-comp {
  background-color: #745e4d;

  &-container {
    height: 100%;
    position: relative;

    .bg-left-top, .bg-right-top, .bg-center-top, .bg-center-bottom {
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      opacity: .1;
      z-index: 0;
      @include mobile {
        display: none;
      }
    }

    .bg-left-top {
      width: 50%;
      height: 500px;
      background-image: url("../../assets/imgs/subRoadmapImg1.svg");
      left: 0;
      top: 0;
    }

    .bg-right-top {
      width: 50%;
      height: 500px;
      background-image: url("../../assets/imgs/subRoadmapImg2.svg");
      top: 0;
      right: 0;
    }

    .bg-center-top {
      width: 90%;
      height: 800px;
      background-image: url("../../assets/imgs/subRoadmapImg3.svg");
      left: 50%;
      margin-top: 500px;
      transform: translateX(-50%);
    }

    .bg-center-bottom {
      width: 90%;
      height: 800px;
      margin-top: 1200px;
      background-image: url("../../assets/imgs/subRoadmapImg4.svg");
    }
  }

  &-footer {
    height: 300px;
    position: relative;

    .footer-left, .footer-right {
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      opacity: .1;
      z-index: 0;
      bottom: 0;
    }

    .footer-left {
      width: 570px;
      height: 400px;
      background-image: url("../../assets/gifs/subRoadmapFooter.gif");
      left: 0;
    }

    .footer-right {
      width: 400px;
      height: 400px;
      background-image: url("../../assets/imgs/subRoadmapImg6.svg");
      right: 0;
    }

    img {
      width: 30%;
    }

    @include mobile {
      height: 150px;
      img {
        width: 80%;
      }
      .footer-left {
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translateX(-50%);
      }
      .footer-right {
        display: none
      }
    }

    @include tablet {
      height: 200px;
      .footer-left {
        left: 50%;
        transform: translateX(-50%);
      }
      .footer-right {
        display: none
      }
    }
  }
}

.roadmap-comp-container .roadmap-comp {
  &-title {
    z-index: 1;

    .title {
      color: #3a241a;
      text-shadow: 0.01738254em -0.0289294em 0.12375em rgba(169, 133, 42, 70%);
      font-family: AboutTitle, sans-serif;
      font-size: 72px;
      text-align: center;
    }

    img {
      width: 40%;
    }

    @include mobile {
      .title {
        font-size: 50px;
      }
      img {
        width: 80%;
      }
    }
  }

  &-detail {
    margin-top: 36px;

    .roadmap-item {
      width: 100%;
      display: grid;
      @include desktop {
        grid-template-columns: 20% 80%;
      }
      @include wide-desktop {
        grid-template-columns: 10% 90%;
      }

      &:not(:first-child) {
        margin-top: 24px;
      }

      &:nth-child(odd) {
        .progress-container .detail, .content-container {
          background-color: #557955;
        }
      }

      &:nth-child(even) {
        .progress-container .detail, .content-container {
          background-color: #fcc03e;
        }
      }

      .progress-container .detail, .content-container {
        border-radius: 20px;
      }

      .progress-container {
        padding: 10px;

        .detail {
          color: #2e281f;
          font-size: 50px;
          font-weight: 700;
          width: 100px;
          height: 100px;
          font-family: RoadmapNumber, sans-serif;
        }
      }

      .content-container {
        min-height: 200px;
        padding: 20px;

        .content {
          color: #2e281f;
          font-size: 25px;
          line-height: 40px;
          text-align: justify;
          font-family: AboutContent, sans-serif;
          @include mobile {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
}