@import "../../styles/rwd";

.faq-comp {
  background-color: #bd9479;

  &-container {
    height: 100%;
  }
}

.faq-comp-container .faq-comp-content {
  background-color: rgba(0, 0, 0, .5);
  padding: 24px;

  .faq-comp-title .title {
    color: #e49d14;
    text-align: center;
    text-shadow: 0 0.0375em 0.28125em rgba(0, 0, 0, 60%);
    font-family: FaqTitle, sans-serif;
    font-size: 72px;
  }

  .faq-content {
    margin-top: 36px;

    .faq-question .question {
      text-align: center;
      font-family: FaqQuestion, sans-serif;
      font-size: 40px;
      color: #03989e;
      text-shadow: 0 0.0375em 0.28125em rgba(0, 0, 0, 60%);
      @include mobile {
        font-size: 20px;
      }
      @include tablet {
        font-size: 30px;
      }
    }

    .faq-answer {
      margin-top: 12px;
      width: 100%;
      min-height: 100px;
      border: 4px solid #e49d14;
      box-shadow: 2px 0 0 #97cbc8 inset, 2px 0 0 #97cbc8;
      padding: 10px;

      .answer {
        color: #000000;
        font-family: FaqQuestion, sans-serif;
        font-size: 22px;
        @include mobile {
          font-size: 18px;
        }
      }
    }
  }
}