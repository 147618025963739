* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "HeaderFont";
  src: url(881b8da5ad9b82b143ab37dcdf069c4c.woff2) format("woff2");
}

@font-face {
  font-family: "ConnectText";
  src: url(232a5c3d425d536fab667982b4af2a2b.woff2) format("woff2");
}

@font-face {
  font-family: "WalletText";
  src: url(90c4bcc588b255c82225626a97d43318.woff2) format("woff2");
}

@font-face {
  font-family: "AboutTitle";
  src: url(d9a711ead10f02aa89010e52fa00b7ca.woff2) format("woff2");
}

@font-face {
  font-family: "AboutContent";
  src: url(f408d10d4bd61dc589f56fe6329fd542.woff2) format("woff2");
}

@font-face {
  font-family: "CommunityContent";
  src: url(df68062a3aa8b2009d36094e05658249.woff2) format("woff2");
}

@font-face {
  font-family: "RoadmapNumber";
  src: url(b25a21661ecd0da6d131b6ee3061b904.woff2) format("woff2");
}

@font-face {
  font-family: "TeamTitle";
  src: url(6169c5d7dbe181f6a0a982b82c673bc5.woff2) format("woff2");
}

@font-face {
  font-family: "TeamUsername";
  src: url(fae8514cc95d3eeb0c84b0b2412bbde4.woff2) format("woff2");
}

@font-face {
  font-family: "TeamUserAbout";
  src: url(0a324e33cb87e4b80e5eba3b4c439099.woff2) format("woff2");
}

@font-face {
  font-family: "TeamUserSocial";
  src: url(97a0034f1e21c26d011f5862d182e7b4.woff2) format("woff2");
}

@font-face {
  font-family: "FaqTitle";
  src: url(e2a933e240db519d216e3ef91616c009.woff2) format("woff2");
}

@font-face {
  font-family: "FaqQuestion";
  src: url(7397d8ff1dd91ec6746d97efe6b9edc5.woff2) format("woff2");
}

@font-face {
  font-family: "Copyright";
  src: url(eb2977182a486c57a8d4bf06f16c4dcc.woff2) format("woff2");
}

.comp-height {
  min-height: 100vh;
  padding: 24px 0;
}