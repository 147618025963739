$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 1024px;
$wide-screen-width: 1200px;

@mixin smallScreen {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin wide-desktop {
  @media (min-width: #{$wide-screen-width}) {
    @content;
  }
}